import React from 'react';
import './WhatWeOffer.css';

const WhatWeOffer = () => {
  return (
    <section className="what-we-offer">
      <h2>What We Offer</h2>
      <div className="service">
        <h3>IT/ITES- Staffing</h3>
        <p>We assist companies in tracing the right talent and deploy professionals who are specific to an industry, geography or business purpose for a definite project timeline on Faskum Technologies payroll...</p>
        <a href="#read-more" className="button">read more</a>
      </div>
      <div className="service">
        <h3>Hire Train Deploy</h3>
        <p>Our campus to corporate program provides qualified and ready to deploy resources for our clients. All hired candidates are trained in the necessary...</p>
        <a href="#read-more" className="button">read more</a>
      </div>
<div className="service">
  <h3>IT Managed Services</h3>
  <p>We execute projects for specific Centers of Excellence like Testing and Infrastructure management services, under the Managed/Co-Managed engagement model...</p>
  <a href="#read-more" className="button">read more</a>
</div>
<div className="service">
  <h3>Easy to Choose a Candidate</h3>
  <p>We ensure all paper work and compliance processes are in place for any particular business process assigned to us.</p>
  <a href="#read-more" className="button">read more</a>
</div>
    </section>
  );
};

export default WhatWeOffer;
