import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <h1>Welcome to the Fascome technology</h1>
      <p>Explore our services.</p>
      <h2>WE are the best for your business!</h2>
      <p>We recognize that our strength and our competitive advantage is and always will be people. We provide opportunities for them to grow and continually improve to make their lives better.</p>
      <h2>Boost your business with Our Quality Job Consultancy Services</h2>
      <p>Our campus to corporate program provides qualified and ready to deploy resources for our clients. All hired candidates are trained in the necessary skill set by our technology experts. The qualified resources are then deployed to the client.</p>
      <a href="#check-it-now" className="button">Check It Now</a>
    </section>
  );
};

export default HeroSection;
