import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import HeroSection from './components/HeroSection';
import WhatWeOffer from './components/WhatWeOffer';
import WhyChooseUs from './components/WhyChooseUs';
import OurAchievement from './components/OurAchievement';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Header from './components/Header';
import Link from './components/Link';
import './App.css';

const history = createBrowserHistory();

function App() {
  return (
    <HistoryRouter history={history}>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/hero" element={<HeroSection />} />
          <Route path="/" element={
            <>
              <HeroSection />
              <WhatWeOffer />
              <WhyChooseUs />
              <OurAchievement />
              <AboutUs />
            </>
          } />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<WhatWeOffer />} />
          <Route path="/achievements" element={<OurAchievement />} />
        </Routes>
        <Footer />
      </div>
    </HistoryRouter>
  );
}

export default App;
