import React from 'react';
import './OurAchievement.css';

const OurAchievement = () => {
  return (
    <section className="our-achievement">
      <h2>Our Achievement</h2>
      <div className="achievement">
        <h3>Clients</h3>
        <p>Number of clients served</p>
      </div>
      <div className="achievement">
        <h3>Placed Candidates</h3>
        <p>Number of candidates placed</p>
      </div>
      <div className="achievement">
        <h3>Monthly Resumes</h3>
        <p>Number of resumes received monthly</p>
      </div>
      <div className="call-to-action">
        <h3>Want the best for your business?</h3>
        <p>Contact us today to learn more about our services.</p>
        <a href="#contact" className="button">Contact Us</a>
      </div>
    </section>
  );
};

export default OurAchievement;
