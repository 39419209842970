import React from 'react';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <h2>Why Choose Us</h2>
      <div className="reason">
        <h3>We are GENUINE</h3>
        <p>All the candidates provided by us are verified in terms of their technical skills and validated all the documents.</p>
      </div>
      <div className="reason">
        <h3>Faskum Technologies provides customers with a comprehensive talent management solution</h3>
        <p>Specific to an industry, geography or business purpose. With over 100 associates proficient in 10+ skill sets, across Automotive industry sectors. They are ably supported by sophisticated internal tools, an industry-leading fulfillment engine, and an unparalleled associate engagement and support framework.</p>
      </div>
      <div className="reason">
        <h3>Verified Candidates</h3>
        <p>We verify candidates first.</p>
      </div>
      <div className="reason">
        <h3>Trained</h3>
        <p>We train the candidates.</p>
      </div>
      <div className="reason">
        <h3>Till Satisfaction</h3>
        <p>We provide plenty of candidates.</p>
      </div>
      <div className="reason">
        <h3>Easy Communication</h3>
        <p>We are easily available.</p>
      </div>
    </section>
  );
};

export default WhyChooseUs;
