import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <section className="about-us">
      <h2>About Us</h2>
      <p>The only staffing company with industry focused verticals, Faskum Technologies provides customers with a comprehensive talent management solution specific to an industry, geography or business purpose.</p>
      <h3>Services</h3>
      <ul>
        <li>IT/ITES- Staffing</li>
        <li>Reverse Partnering</li>
        <li>Rebadging</li>
        <li>Hire Train Deploy</li>
      </ul>
    </section>
  );
};

export default AboutUs;
