import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header>
      <div className="background-image">
        <div className="overlay">
          <div className="content">
            <nav className="main-nav">
              <div className="nav-left">
                <Link to="/" className="logo">
                  <img src="" alt="Faskum Technologies" />
                </Link>
              </div>
              <div className="nav-right">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/services">Services</Link>
                <Link to="/achievements">Achievements</Link>
                <Link to="/contact">Contact</Link>
              </div>
            </nav>
            
            <div className="hero-section">
              <h1>Innovative Technology Solutions</h1>
              <h2>Empowering Business Transformation</h2>
              <p className="subtitle">Delivering enterprise-grade solutions that drive growth and efficiency</p>
              
              <div className="cta-buttons">
                <Link to="/contact" className="primary-btn">Get Started</Link>
                <Link to="/services" className="secondary-btn">Our Services</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
